import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { type Playlist as PlaylistType } from 'constants/types'
import ExploreLessonsWithoutFilters from 'features/explore/components/ExploreLessonsWithoutFilters'
import Playlist from 'features/playlists/components/Playlist'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as challengeLib from 'libs/challenge'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as lessonApiRequest from 'libs/lesson-api-request'

export default function ExplorePlaylists() {
  useEventPageVisited('Playlists')
  const queryData = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { draft: { ne: true }, playlists: { ne: null }, template: { eq: "blog" } }
        }
      ) {
        nodes {
          id
          frontmatter {
            playlists
          }
        }
      }
    }
  `)
  const [data, setData] = React.useState<PlaylistType[]>([])

  React.useEffect(() => {
    async function getData() {
      const response = await lessonApiRequest.getPlaylists().catch((error: Error) => {
        console.error(error)
      })

      if (_.isEmpty(response.data)) return

      let allPlaylists = response.data
      // include collections associated with any active challenges
      const challengeResponse = await challengeApiRequest
        .getActiveChallenges({ type: challengeLib.CHALLENGE_TYPE.ONEOFF })
        .catch((error: Error) => {
          console.error(error)
        })

      if (!_.isEmpty(challengeResponse?.data)) {
        const collectionId = challengeResponse.data[0].collectionId
        const playlistResponse = await lessonApiRequest
          .getPlaylist(collectionId)
          .catch((error: any) => {
            console.error(error)
          })

        if (!_.isEmpty(playlistResponse?.data)) {
          allPlaylists = allPlaylists.concat(playlistResponse.data.playlist)
        }
      }

      setData(_.orderBy(allPlaylists, 'created_at', 'desc'))
    }
    getData()
  }, [])

  return (
    <ExploreLessonsWithoutFilters
      title="Collections"
      loading={_.isEmpty(data)}
      className="ExplorePlaylists">
      {data.map((item) => (
        <Playlist
          data={item}
          blogs={queryData.allMdx.nodes.filter((blog) =>
            blog.frontmatter.playlists?.includes(item.id)
          )}
          key={item.id}
        />
      ))}
    </ExploreLessonsWithoutFilters>
  )
}
