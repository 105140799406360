import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Footer from 'components/Footer'
import Head from 'components/Head'
import Text from 'components/Text'
import View from 'components/View'
import styles from './ProgramUpdateLayout.module.scss'
import logoImage from './images/logo.svg'

interface Props {
  children: any
  footer?: boolean
  title: string
  titlePrefix?: string
}

export default function ProgramUpdateLayout({
  children,
  footer = true,
  title,
  titlePrefix,
}: Props) {
  React.useEffect(() => {
    // if (globalContext.user?.isOnboardingCompleted) navigate(paths.HOME_AUTHED)
    // check only on mount so we don't redirect on submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames('ProgramUpdateLayout', styles.this)}>
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <div className={styles.brand}>
        <img src={logoImage} alt="Bold" className={styles.brandImage} />
      </div>
      <Text casing="upper" element="header" size="small" weight="bold" className={styles.heading}>
        {titlePrefix && <Text className={styles.step}>{titlePrefix}</Text>}
        {title}
      </Text>
      <View color="grey" footer className={styles.main}>
        <Container flush>{children}</Container>
      </View>
      {footer && <Footer />}
    </div>
  )
}
