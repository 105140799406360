import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import * as user from 'libs/user'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import constants from 'styles/constants.module.scss'

export default function DashboardHosSurvey() {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const { width } = useWindowSize()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [isComplete, setIsComplete] = React.useState(false)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  React.useEffect(() => {
    async function getCheckin() {
      if (!globalContext.user?.id) return
      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'hos_survey',
          code: 'hos',
          activeOnly: false,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            type: 'hos_survey',
          })
        })

      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext.user.id, checkinResponse.data, globalContext.analytics)
        )
      }
    }

    getCheckin()
  }, [globalContext.analytics, globalContext.user?.id])

  React.useEffect(() => {
    if (!checkinTracker) return
    setIsComplete(checkinTracker.status === 'complete')

    const modalOpen =
      checkinTracker.status === 'not_started' &&
      !cookie.getCookie(storage.HOS_SURVEY_MODAL) &&
      globalContext.user?.allTimeClassesWatched > 0
    setIsModalOpen(modalOpen)

    if (modalOpen) {
      globalContext.analytics?.trackEvent(events.HOS_SURVEY_MODAL_SHOWN)
    }
  }, [checkinTracker, globalContext.analytics, globalContext.user])

  function handleClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_HOS_TAKE_SURVEY, {
      checkinStatus: checkinTracker?.status,
    })
    navigate(paths.HOS_SURVEY)
  }

  function handleModalButtonClick() {
    globalContext.analytics?.trackEvent(events.HOS_SURVEY_MODAL_START_SURVEY, {
      checkinStatus: checkinTracker?.status,
    })
    navigate(paths.HOS_SURVEY)
  }

  async function handleCollectionClick() {
    const collectionId = await user.getHosCollectionID(checkinTracker)

    globalContext.analytics?.trackEvent(events.DASHBOARD_HOS_VIEW_COLLECTION, {
      collectionId,
    })

    const pathName = collectionId ? `${paths.PLAYLIST}${collectionId}` : paths.PLAYLISTS
    navigate(pathName, {
      state: {
        from: location.pathname,
      },
    })
  }

  async function handleRequestClose() {
    cookie.createCookie(storage.HOS_SURVEY_MODAL, storage.MODAL_DISMISSED)
    setIsModalOpen(false)
    await globalContext.analytics?.trackEvent(events.HOS_SURVEY_MODAL_DISMISSED)
  }

  return (
    <>
      <Modal isOpen={isModalOpen} size="small" onRequestClose={handleRequestClose}>
        <ModalBox
          title={
            <>
              {globalContext.user?.firstName ? `${globalContext.user.firstName}’s` : ''} Healthy
              Habit Kit
            </>
          }
          subtext="Take a 2-minute assessment to unlock your curated collection for building healthy habits in 2025."
          align="left"
          size="small"
          titleAlign="left">
          <Button full onClick={handleModalButtonClick}>
            Start
          </Button>
        </ModalBox>
      </Modal>
      <Box color="white" className="DashboardHosSurvey">
        <MediaObject
          figure={
            isComplete ? (
              <Button color="white" onClick={handleCollectionClick}>
                View collection
              </Button>
            ) : (
              <Button
                color={checkinTracker?.status === 'partial' ? 'purple' : 'yellow'}
                onClick={handleClick}>
                {checkinTracker?.status === 'partial' ? 'Complete the survey' : 'Get started'}
              </Button>
            )
          }
          center
          figurePosition="right"
          reset="tablet">
          <Text
            align={width <= Number(constants.TABLET_PX) ? 'center' : undefined}
            color="black"
            element="div">
            <Heading level={2} levelStyle={4}>
              {isComplete
                ? globalContext.user?.firstName
                  ? `${globalContext.user.firstName}’s`
                  : 'Your'
                : 'Unlock Your'}{' '}
              Healthy Habit Kit
            </Heading>
            <Text element="p" flush={!isComplete}>
              {isComplete
                ? 'Check out your customized collection packed with expert blogs, exercise classes, and Bold Talks.'
                : `${checkinTracker?.status === 'partial' ? 'Finish answering' : 'Answer'} a few questions to get access to a customized collection packed with expert blogs, exercise classes, and Bold Talks.`}
            </Text>
            {isComplete && (
              <Text element="p" flush weight="bold">
                🎉 Your collection is ready!
              </Text>
            )}
          </Text>
        </MediaObject>
      </Box>
    </>
  )
}
