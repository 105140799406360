import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import type { LessonStream } from 'libs/lesson'
import * as liveLessons from 'libs/live-lessons'
import DashboardLessons from '../DashboardLessons'

type Props = {
  data: LessonStream[]
} & Component

export default function DashboardLive({ data }: Props) {
  const globalContext = useGlobalContext()
  const [lessons, setLessons] = React.useState<LessonStream[]>([])

  React.useEffect(() => {
    setLessons(liveLessons.sortAndFilterUpcoming(data))
  }, [data])

  function handleButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_LIVE_LESSONS_VIEW_ALL_CLICK)
  }

  if (_.isEmpty(data)) return null

  return (
    <DashboardLessons
      title="Upcoming live classes"
      action={
        <Button to={paths.LIVE_LESSONS} onClick={handleButtonClick}>
          See all live classes
        </Button>
      }
      data={lessons}
      hover={false}
      loading={_.isEmpty(lessons)}
      className="DashboardLiveLessons"
    />
  )
}
