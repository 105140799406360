import { differenceInCalendarDays } from 'date-fns'
import pluralize from 'pluralize'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import DateTime from 'components/DateTime'
import Fieldset from 'components/Fieldset'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import SubscriptionCancelButton from 'features/user/components/SubscriptionCancelButton'
import SubscriptionChangeButton from 'features/user/components/SubscriptionChangeButton'
import SubscriptionPaymentMethodChangeButton from 'features/user/components/SubscriptionPaymentMethodChangeButton'
import SubscriptionPlanWithPrice from 'features/user/components/SubscriptionPlanWithPrice'
import SubscriptionReactivateButton from 'features/user/components/SubscriptionReactivateButton'
import * as user from 'libs/user'

export default function Subscription(props) {
  const globalContext = useGlobalContext()

  function handleEnterpriseEligibleButtonClick() {
    globalContext.analytics?.trackEvent(events.SETTINGS_ENTERPRISE_ELIGIBILITY_BUTTON_CLICK)
  }

  const buttonProps = {
    level: 'text',
    size: 'small',
  }

  const textProps = {
    element: 'p',
    flush: true,
    size: 'small',
  }

  if (globalContext.isEnterprise && globalContext.user.enterpriseMember?.validated) return null

  const hasCancelledSubscription = globalContext.user?.subscription?.cancel_at_period_end
  const hasCurrentSubscription = user.hasCurrentSubscription(globalContext.user)

  const trialEndCopy = (
    <>
      After that,{' '}
      {user.willBeCharged(globalContext.user) ? (
        <Text color="purple" weight="bold">
          you will be charged
        </Text>
      ) : (
        <>
          <Text color="purple" weight="bold">
            you will be downgraded
          </Text>{' '}
          to Bold Basic
        </>
      )}
      .
    </>
  )

  return (
    <Fieldset
      {...props}
      actions={
        <Buttons>
          <SubscriptionPaymentMethodChangeButton {...buttonProps} />
          {!!globalContext.user?.subscription && (
            <Button to={paths.SETTINGS_INVOICES} {...buttonProps}>
              View invoices
            </Button>
          )}
        </Buttons>
      }
      footer={
        <>
          <Row size="xsmall">
            <Text element="div" size="small">
              <Buttons align="right">
                <SubscriptionChangeButton {...buttonProps} />
                <SubscriptionCancelButton {...buttonProps} />
                <SubscriptionReactivateButton {...buttonProps} />
              </Buttons>
            </Text>
          </Row>
        </>
      }
      className="Subscription">
      {hasCurrentSubscription &&
        (globalContext.user?.defaultStripePaymentSource || user.isFreeUser(globalContext.user)) && (
          <Row size="small">
            <SubscriptionPlanWithPrice />
          </Row>
        )}
      {user.isTrialUser(globalContext.user) &&
        !hasCancelledSubscription &&
        !!globalContext.user?.subscription?.trial_end && (
          <Text {...textProps}>
            Your free Bold Premium trial is ending in{' '}
            {pluralize(
              'day',
              differenceInCalendarDays(
                new Date(globalContext.user.subscription.trial_end * 1000),
                new Date()
              ),
              true
            )}
            . {trialEndCopy}{' '}
            {!user.willBeCharged(globalContext.user) && (
              <SubscriptionChangeButton color="purple" level="text">
                Want to keep access?
              </SubscriptionChangeButton>
            )}
          </Text>
        )}
      {hasCancelledSubscription && (
        <Text {...textProps}>
          {globalContext.user?.subscription?.trial_end ? (
            <>
              Your subscription has been cancelled, and you will not be charged in the future. You
              will retain access to Bold Premium until your trial ends on{' '}
              <DateTime data={new Date(globalContext.user.subscription.trial_end * 1000)} />.
            </>
          ) : (
            'Your subscription has been cancelled, and you will not be charged in the future.'
          )}
        </Text>
      )}
      {!user.isEnterpriseUser(globalContext.user) && (
        <Buttons align="right">
          <Button to={paths.ENTERPRISE_ELIGIBLE} onClick={handleEnterpriseEligibleButtonClick}>
            See if your health plan offers Bold free
          </Button>
        </Buttons>
      )}
    </Fieldset>
  )
}
