import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import View from 'components/View'
import LessonFavoriteButton from 'features/lesson/components/LessonFavoriteButton'
import LessonTrainer from 'features/lesson/components/LessonTrainer'
import LessonVideo from 'features/lesson/components/LessonVideo'
import ProgramHeaderPostLesson from 'features/program/components/Program/components/ProgramHeaderPostLesson'
import ProgramGoal from 'features/program/components/ProgramGoal'
import { useUserChallenges } from 'libs/challenge-query'
import type { Lesson } from 'libs/lesson'
import * as logging from 'libs/logging'
import * as navigation from 'libs/navigation'
import { useLessonsForProgramDynamic, useProgram } from 'libs/program-query'
import styles from './ProgramHeader.module.scss'
import ProgramLessonSkipButton from './components/ProgramLessonSkipButton'
import recommendationCopyConstants from './constants/recommendation-copy'
import { ReactComponent as ChairSvg } from './images/chair.svg'

function getRecommendationIntensityCopy(lessonIntensity?: number) {
  if (!lessonIntensity) return null

  let intensityCopy = recommendationCopyConstants.intensity.MEDIUM

  if (lessonIntensity < 3.5) {
    intensityCopy = recommendationCopyConstants.intensity.LOW
  }
  if (lessonIntensity > 6.5) {
    intensityCopy = recommendationCopyConstants.intensity.HIGH
  }

  return intensityCopy
}

function getRecommendationCopy(lesson: Lesson) {
  const { instructorName } = lesson

  if (!instructorName || !lesson.type) return null

  const trainerCopy =
    recommendationCopyConstants.instructor[instructorName] ||
    `${
      instructorName !== 'Guest Trainer' ? instructorName.split(' ')[0] : 'This instructor'
    } focuses on building strength and stability`
  const positionCopy =
    recommendationCopyConstants.position[lesson.type] ||
    'Exercises designed for a safe and effective workout'

  return [trainerCopy, positionCopy, getRecommendationIntensityCopy(lesson.average_intensity)]
}

type Props = {
  lessonOverride: Lesson | undefined
  // eslint-disable-next-line no-unused-vars
  // onLessonClick: (lesson: Lesson) => void
  onLessonOverride: () => void
  // eslint-disable-next-line no-unused-vars
  onLessonSkipped: (lessonId: string) => void
  onPlaySkippedLesson: () => void
  // eslint-disable-next-line no-unused-vars
  onVideoEnd: () => Promise<void>
}

export default function ProgramHeader({
  lessonOverride,
  // onLessonClick,
  onLessonOverride,
  onLessonSkipped,
  onPlaySkippedLesson,
  onVideoEnd,
}: Props) {
  const { data: programData, mutate: mutateProgramData } = useProgram({ revalidateOnFocus: false })
  const program = programData?.data
  const { data: userProgramLessonsData, mutate: mutateUserProgramLessons } =
    useLessonsForProgramDynamic(program?.id, {
      dependencies: [programData],
      revalidateOnFocus: false,
    })
  const programLessons = userProgramLessonsData?.data
  const { mutate: mutateUserProgramChallenges } = useUserChallenges(
    {
      type: 'program',
    },
    {
      revalidateOnFocus: false,
    }
  )
  const [currentLesson, setCurrentLesson] = React.useState<Lesson | undefined>()
  const [previousLesson, setPreviousLesson] = React.useState<Lesson>()
  const [nextLesson, setNextLesson] = React.useState<Lesson>()
  const [showPostLesson, setShowPostLesson] = React.useState(false)
  const [lessonIsActive, setLessonIsActive] = React.useState(false)
  const [sessionId, setSessionId] = React.useState(null)

  React.useEffect(() => {
    if (_.isEmpty(programLessons) || lessonIsActive) return
    logging.toDatadog(`ProgramHeader Enter useEffect`, {
      lessonOverride,
      programLessons,
      showPostLesson,
    })

    if (showPostLesson) {
      logging.toDatadog(
        `ProgramHeader Enter showPostLesson, current previousLesson = ${previousLesson?.id}`
      )
      if (previousLesson) return
      logging.toDatadog(
        `ProgramHeader Enter showPostLesson, set previousLesson = ${currentLesson?.id}`
      )
      logging.toDatadog(`ProgramHeader Enter showPostLesson, set currentLesson = ${nextLesson?.id}`)
      setPreviousLesson(currentLesson)
      setCurrentLesson(nextLesson)
      setNextLesson(null)
    } else {
      let temporaryCurrentLesson = null
      let temporaryNextLesson = null
      for (const lesson of programLessons) {
        // if no userview or userview is incomplete
        if (!!temporaryCurrentLesson && !!temporaryNextLesson) {
          break
        }
        if (!lesson?.userView || !_.includes(['completed', 'skipped'], lesson?.userView?.status)) {
          if (!temporaryCurrentLesson) {
            temporaryCurrentLesson = lesson
          } else if (!temporaryNextLesson) {
            temporaryNextLesson = lesson
          }
        }
      }
      logging.toDatadog(`ProgramHeader after loop, currentLesson = ${temporaryCurrentLesson?.id}`)
      logging.toDatadog(`ProgramHeader after loop, nextLesson = ${temporaryNextLesson?.id}`)
      if (lessonOverride) {
        logging.toDatadog(
          `ProgramHeader Enter lessonOverride, set current lesson = ${lessonOverride?.id}`
        )
        logging.toDatadog(
          `ProgramHeader Enter lessonOverride, set next lesson = ${temporaryCurrentLesson?.id}`
        )

        setCurrentLesson(_.find(programLessons, (lesson) => lesson.id === lessonOverride.id))
        setNextLesson(temporaryCurrentLesson)
        setShowPostLesson(false)
      } else {
        setCurrentLesson(temporaryCurrentLesson)
        setNextLesson(temporaryNextLesson)
      }
      setPreviousLesson(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonOverride, programLessons, lessonIsActive, showPostLesson])

  const isEducation = currentLesson?.purpose === 'education'

  function handlePlay() {
    setLessonIsActive(true)
  }

  function handleVideoProgress() {
    // can take in parameter progressSeconds: number
    setLessonIsActive(true)
  }

  function handleSkip(lessonId: string) {
    setLessonIsActive(false)
    mutateUserProgramLessons()
    if (onLessonSkipped) {
      onLessonSkipped(lessonId)
    }
  }

  function handleFeedbackSuccess() {
    onLessonOverride()
    mutateUserProgramChallenges()
  }

  const handlePlaySkippedLesson = () => {
    if (onPlaySkippedLesson) {
      onPlaySkippedLesson()
    }
  }

  async function handleVideoEnd() {
    setLessonIsActive(false)
    setShowPostLesson(true)
    await onVideoEnd()
    await mutateProgramData()
    await mutateUserProgramLessons()
  }

  function handleNextLessonClick() {
    setShowPostLesson(false)
    mutateUserProgramChallenges()
    navigation.scrollToTop()

    // if (onLessonClick) onLessonClick(currentLesson) // update tags on videos in ProgramBlock
  }

  function handleSessionIdUpdate(newSessionId: string) {
    setSessionId(newSessionId)
  }

  if (!currentLesson) return null

  const hasRequiredItems =
    currentLesson.requiredItems && currentLesson.requiredItems?.toLowerCase() !== 'none'

  const isMobile = /android|iphone/i.test(navigator.userAgent)

  const recommendationCopy = getRecommendationCopy(currentLesson)

  return (
    <header className="ProgramHeader">
      <View>
        {showPostLesson ? (
          <ProgramHeaderPostLesson
            data={previousLesson}
            lessonNext={currentLesson}
            onLessonNextClick={handleNextLessonClick}
          />
        ) : (
          <div className={styles['video-and-sidebar']}>
            <div className={classNames(styles['video-wrap'])}>
              <LessonVideo
                lesson={currentLesson}
                playSkippedLesson={handlePlaySkippedLesson}
                thumbnail={currentLesson.videoThumbnail}
                url={currentLesson.videoLink}
                userExerciseProgramId={program.id}
                className={styles.video}
                onEnd={handleVideoEnd}
                onPlay={handlePlay}
                onProgress={handleVideoProgress}
                onSessionIdUpdate={handleSessionIdUpdate}
              />
            </div>
            <Text element="div" className={styles.sidebar}>
              <div>
                <Heading level={1} levelStyle={3}>
                  {currentLesson.videoTitle}
                </Heading>
                <Row size="smallx">
                  <LessonTrainer data={currentLesson} size="large" weight="normal" />
                </Row>
                <Row size="small">
                  <LessonFavoriteButton lesson={currentLesson} lessonId={currentLesson.id} />
                </Row>
                <Row size="xmedium">
                  <Row size="small">
                    <div className={styles.skip}>
                      <div className={styles['skip--body']}>
                        {recommendationCopy && !isEducation ? (
                          <>
                            <Text element="h2" weight="bold">
                              Why we chose this class for you
                            </Text>
                            <Divider thin size="xsmall" />
                            <Copy>
                              <Text element="ul">
                                {recommendationCopy.filter(Boolean).map((item) => (
                                  <Row element="li" key={item} size="xsmall">
                                    {item}
                                  </Row>
                                ))}
                              </Text>
                            </Copy>
                          </>
                        ) : (
                          <>
                            <Heading levelStyle={6}>About class</Heading>
                            <Text element="p" flush>
                              {currentLesson.exerciseBlocks?.exerciseBlockLesson
                                ?.blockLessonDescription || currentLesson.instructions}
                            </Text>
                          </>
                        )}
                      </div>
                      <div className={styles['skip--footer']}>
                        <MediaObject
                          figure={
                            <ProgramLessonSkipButton
                              lesson={currentLesson}
                              sessionId={sessionId}
                              userExerciseProgramId={program.id}
                              onFeedbackSuccess={handleFeedbackSuccess}
                              onSkip={handleSkip}
                            />
                          }
                          center
                          figurePosition="right">
                          <Text color="black" weight="bold">
                            Want to take a different class?
                          </Text>
                        </MediaObject>
                      </div>
                    </div>
                  </Row>
                  {hasRequiredItems && !recommendationCopy && (
                    <>
                      <Heading levelStyle={6}>Equipment</Heading>
                      <Copy>
                        <TextWithIcon
                          icon={
                            currentLesson.requiredItems?.toLowerCase().includes('chair') ? (
                              <ChairSvg />
                            ) : undefined
                          }
                          inline>
                          {currentLesson.requiredItems?.replace(/,/g, ', ')}
                        </TextWithIcon>
                      </Copy>
                    </>
                  )}
                </Row>
                <Row flush={!isMobile} size="xmedium">
                  <ProgramGoal />
                </Row>
              </div>
            </Text>
          </div>
        )}
      </View>
    </header>
  )
}
