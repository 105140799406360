import _ from 'lodash'
import React from 'react'
import * as rdd from 'react-device-detect'
import Announcement from 'components/Announcement'
import Container from 'components/Container'
import Rows from 'components/Rows'
import View from 'components/View'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import AppDownloadModal from 'features/app/components/AppDownloadModal'
import Challenge from 'features/user/components/Challenge'
import * as cookie from 'libs/cookie'
import * as lessonLib from 'libs/lesson'
import type { Lesson } from 'libs/lesson'
import * as lessonsQuery from 'libs/lessons-query'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Dashboard.module.scss'
import DashboardActivity from './components/DashboardActivity'
import DashboardHosSurvey from './components/DashboardHosSurvey'
import DashboardLessonsFavorite from './components/DashboardLessonsFavorite'
import DashboardLessonsRecommended from './components/DashboardLessonsRecommended'
import DashboardLiveLessons from './components/DashboardLiveLessons'
import DashboardToday from './components/DashboardToday'

export default function Dashboard() {
  const globalContext = useGlobalContext()
  const announcementText = rdd.isMobile
    ? 'Move & Learn with daily recommended classes or classes of your choice.'
    : `Hi${globalContext.user?.firstName ? ` ${globalContext.user?.firstName}` : ''}! Check out your Home page for two recommended classes
    everyday — one exercise class and one education class. Feel free to choose other classes
    in each of these categories, they all count toward Today’s plan!`
  const [announcementCopy, setAnnouncementCopy] = React.useState(announcementText)
  const [dashboardData, setDashboardData] = React.useState<null | any>(null)
  const [showTodaysPlanAnnouncement, setShowTodaysPlanAnnouncement] = React.useState(
    !cookie.getCookie(storage.TODAYS_PLAN_ANNOUNCEMENT_KEY)
  )
  const { data, isLoading } = lessonsQuery.useUserLessonsFavorite()
  const lessonsFavoriteData = data?.data

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getData() {
      const response = await userApiRequest.getUserDashboard()

      if (response.statusCode !== 200) return
      setDashboardData(response.data)
    }
    getData()
  }, [globalContext.user])

  function handleMoveLessonChange(lesson: Lesson) {
    if (lessonLib.isEducationClass(lesson?.classType)) {
      setAnnouncementCopy(
        'Check out your Home page for two recommended education classes for today.'
      )
    }
  }

  function handleTodaysPlanAnnouncementClick() {
    cookie.createCookie(storage.TODAYS_PLAN_ANNOUNCEMENT_KEY, storage.ANNOUNCEMENT_DISMISSED)
    setShowTodaysPlanAnnouncement(false)
  }

  const hasLessonsFavorite = !isLoading && lessonsFavoriteData && !_.isEmpty(lessonsFavoriteData)

  return (
    <div className="Dashboard" data-test-id="dashboard">
      <AppDownloadModal />
      {showTodaysPlanAnnouncement && (
        <Announcement
          title="Introducing Today’s plan"
          section
          onCloseClick={handleTodaysPlanAnnouncementClick}>
          {announcementCopy}
        </Announcement>
      )}
      <View color="grey" className={styles.view}>
        <Container flush size="xlargex">
          <Rows size="large">
            <DashboardToday onMoveLessonChange={handleMoveLessonChange} />
            <DashboardActivity data={dashboardData?.activity} />
            <DashboardHosSurvey />
            <DashboardLiveLessons data={dashboardData?.liveLessons} />
            <DashboardLessonsFavorite data={lessonsFavoriteData} isLoading={isLoading} />
            {!hasLessonsFavorite && <DashboardLessonsRecommended />}
            <Challenge from={paths.HOME_AUTHED} />
          </Rows>
        </Container>
      </View>
    </div>
  )
}
