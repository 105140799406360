import classNames from 'classnames'
import React from 'react'
import ButtonWithModal from 'components/ButtonWithModal'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as api from 'libs/api'
import * as notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import * as user from 'libs/user'

type Props = {
  children?: React.ReactNode
  onClick?: () => void
  showModal?: boolean
} & Component

export default function SubscriptionCancelButton({
  children = 'Cancel subscription',
  className,
  onClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()

  function handleClick() {
    globalContext.analytics?.trackEvent(events.SUBSCRIPTION_CANCEL_BUTTON_CLICK)
    if (onClick) onClick()
  }

  async function cancelSubscription() {
    if (!globalContext.user) return

    const response = await subscriptionApiRequest.updateSubscription(
      globalContext.user?.subscription?.id,
      {
        action: api.ACTION_REMOVE_USER_FROM_SUBSCRIPTION,
      }
    )

    if (response.code === statusCodes.POST_SUCCESS) {
      globalContext.analytics?.trackEvent(events.USER_CANCEL_SUBSCRIPTION, {
        subscriptionId: globalContext.user?.subscription?.id,
      })

      await globalContext.update({
        user: {
          ...globalContext.user,
          subscription: response.data.subscription,
        },
      })
      await globalContext.updateUser()

      notifications.notifyAndRedirect({
        message: response.message,
        to: paths.SETTINGS,
      })
    } else {
      notifications.notifyError('Something went wrong. Please try again later.')
    }
  }

  function handleConfirm() {
    cancelSubscription()
  }

  if (
    globalContext.isEnterprise ||
    user.isFreeUser(globalContext.user) ||
    !globalContext.user?.subscription ||
    // active but has previously cancelled
    globalContext.user?.subscription?.cancel_at_period_end ||
    // always let people who will be charged at the end of the trial cancel
    (user.isTrialUser(globalContext.user) && !user.willBeCharged(globalContext.user))
  ) {
    return null
  }

  return (
    <ButtonWithModal
      {...props}
      onClick={handleClick}
      modalProps={{
        cancelText: 'No',
        confirmButtonProps: {
          children: 'Yes, cancel',
          color: 'red',
        },
        size: 'small',
        title: 'Are you sure you want to cancel your Bold Premium subscription?',
        onConfirm: handleConfirm,
      }}
      modalType="confirm"
      className={classNames('SubscriptionCancelButton', className)}>
      {children}
    </ButtonWithModal>
  )
}
