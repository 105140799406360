import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import ModalConfirm from 'components/ModalConfirm'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import Auth from 'features/auth/components/Auth'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import styles from './EnterpriseEligible.module.scss'
import EnterpriseEligibilityForm from '../EnterpriseEligibilityForm'

export default function EnterpriseEligible() {
  const globalContext = useGlobalContext()
  const [showModal, setShowModal] = React.useState(false)
  const isSpecial = user.hasHomeSpecial()

  function handleSuccess(updatedUser: User) {
    if (updatedUser) {
      const enterpriseName = updatedUser.enterpriseMember?.enterprise?.name
      notifications.notifySuccess(
        `Congrats, you are eligible${enterpriseName ? ` with ${enterpriseName}` : ''}!`
      )
      setShowModal(true)
    } else {
      notifications.notifyError('Sorry, we couldn’t verify your eligibility')
      // todo - weird UX for special users who recheck elig from settings later & get directed back to program page if it fails to convert them
      navigate(isSpecial ? paths.ONBOARDING_PROGRAM_BUILD : paths.SETTINGS)
    }
  }

  function handleCloseConfirmModal() {
    navigate(paths.SETTINGS)
  }

  function handleConfirm() {
    navigate(paths.HOME_AUTHED)
  }

  async function handleSubmit() {
    await globalContext.analytics?.trackEvent('Settings page eligibility recheck: click submit')
  }

  return (
    <View className="EnterpriseEligible">
      <Container size="xxlarge">
        <Box flush>
          <Auth>
            <div className={styles.body}>
              <Title subtext="Enter your information to see if you’re eligible for Bold at no cost through your health plan.">
                Check your eligibility
              </Title>
              <EnterpriseEligibilityForm
                from="settings"
                onSubmit={handleSubmit}
                onSuccess={handleSuccess}
              />
            </div>
          </Auth>
        </Box>
      </Container>
      <ModalConfirm
        title="Woohoo! You’re verified!"
        confetti
        confirmText="See my classes"
        hideCancel
        isOpen={showModal}
        skipRequestCloseOnConfirm
        onConfirm={handleConfirm}
        onRequestClose={handleCloseConfirmModal}>
        <Text element="p">
          With your Premium Membership, you can take unlimited programs and classes, any time you
          want.
        </Text>
      </ModalConfirm>
    </View>
  )
}
