import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import SettingsHeaderBack from 'features/user/components/SettingsHeaderBack'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { ReactComponent as StarSvg } from './images/star.svg'

export default function SettingsSubscriptionChangeSuccess() {
  useEventPageVisited(events.SETTINGS_CHANGE_SUBSCRIPTION_SUCCESS_PAGE)
  const globalContext = useGlobalContext()

  return (
    <View className="SettingsSubscriptionChangeSuccess">
      <SettingsHeaderBack />
      <Container flush size="medium">
        <Box align="center" color="white">
          <Row>
            <Title image={<StarSvg aria-hidden />}>
              Thank you, {globalContext.user.firstName}! Let’s get you moving.
            </Title>
            <Text element="p">
              Your Bold membership is now active. A confirmation email has been sent to{' '}
              {globalContext.user.email}. We are excited to welcome you to this amazing community.
            </Text>
            <Text element="p">Let’s be Bold!</Text>
          </Row>
          <Button to={paths.getProgramPath(globalContext.user)}>View your program</Button>
        </Box>
      </Container>
    </View>
  )
}
