import React from 'react'
import Media from 'react-media'
import Container from 'components/Container'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Rows from 'components/Rows'
import Text from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import ActivityView from 'features/user/components/ActivityView'
import Challenge from 'features/user/components/Challenge'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as date from 'libs/date'
import * as user from 'libs/user'
import constants from 'styles/constants.module.scss'
import styles from './Activity.module.scss'
import Calendar from './components/Calendar'
import LessonsHistory from './components/LessonsHistory'
import Stats from './components/Stats'

export default function Activity() {
  useEventPageVisited('Activity')
  const globalContext = useGlobalContext()
  const [hasLessonsHistory, setHasLessonsHistory] = React.useState(true)
  const [hasStats, setHasStats] = React.useState(true)

  return (
    <ActivityView title="Activity">
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <Container flush size="xxlarge">
        <Rows size="large">
          {hasStats && <Stats onEmpty={() => setHasStats(false)} />}
          {user.hasChallenges(globalContext.user) && (
            <section>
              <Heading level={2} levelStyle={4}>
                Challenge
              </Heading>
              <Challenge from={paths.ACTIVITY} />
            </section>
          )}
          <section>
            <Heading align="left" level={2} levelStyle={4} className={styles['calendar--title']}>
              30-day activity
              <Media query={{ maxWidth: constants.TABLET }}>
                <>
                  <div className={styles['calendar--title--divider']} />
                  <span className={styles['calendar--title--month']}>
                    {date.MONTHS[new Date().getMonth()]}
                  </span>
                </>
              </Media>
            </Heading>
            <Calendar />
          </section>
          {hasLessonsHistory && (
            <section>
              <Heading level={2} levelStyle={4}>
                Class history
              </Heading>
              <Row size="small">
                <LessonsHistory onEmpty={() => setHasLessonsHistory(false)} />
              </Row>
              <Text>End of class history</Text>
            </section>
          )}
        </Rows>
      </Container>
    </ActivityView>
  )
}
