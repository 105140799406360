import classNames from 'classnames'
import React from 'react'
import Image from 'components/Image'
import Link from 'components/Link'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Card.module.scss'

type Props = {
  children?: React.ReactNode
  image: {} | string
  onClick?: () => void
  title: string
  to?: string
} & Component

export default function Card({ children, className, image, onClick, title, to, ...props }: Props) {
  const isInteractive = onClick || to
  const Component = isInteractive ? Link : Text

  return (
    <Component
      {...props}
      to={to}
      element={!isInteractive ? 'figure' : undefined}
      className={classNames('Card', styles.this, className)}
      onClick={onClick}>
      <Image src={image} className={styles.image} />
      <div className={styles.body}>
        <Text element="h3" heading weight="bold" wrap="balance" className={styles.title}>
          {title}
        </Text>
        {children}
      </div>
    </Component>
  )
}
