import { navigate } from 'gatsby'
import React from 'react'
import paths from 'constants/paths'
import ProgramBuild from 'features/program/components/ProgramBuild'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as userLib from 'libs/user'

export default function OnboardingProgramBuild() {
  useEventPageVisited('Onboarding program build')
  const isSpecial = userLib.hasHomeSpecial()
  const pathToNavigateTo = isSpecial ? paths.ONBOARDING_PROGRAM_INTRODUCTION : paths.HOME_AUTHED

  return (
    <ProgramBuild
      className="OnboardingProgramBuild"
      onComplete={() => navigate(pathToNavigateTo, { replace: true })}
    />
  )
}
