import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Head from 'components/Head'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingCheckinSingleForm from 'features/onboarding/components/OnboardingCheckinSingleForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import styles from './HosSurvey.module.scss'
import { ReactComponent as CompleteSvg } from './images/clap-hands.svg'

export default function HosSurvey() {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [isComplete, setIsComplete] = React.useState<boolean>(false)

  React.useEffect(() => {
    async function getCheckin() {
      if (!globalContext.user?.id) return
      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'hos_survey',
          code: 'hos',
          activeOnly: false,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            type: 'hos_survey',
          })
        })

      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext.user.id, checkinResponse.data, globalContext.analytics)
        )
      }
    }

    getCheckin()
  }, [globalContext.analytics, globalContext.user?.id])

  React.useEffect(() => {
    if (!checkinTracker) return
    setIsComplete(checkinTracker.status === 'complete')
  }, [checkinTracker])

  async function handleClick() {
    const collectionId = await user.getHosCollectionID(checkinTracker)

    globalContext.analytics?.trackEvent(events.VIEW_HOS_COLLECTION, {
      collectionId,
    })

    const pathName = collectionId ? `${paths.PLAYLIST}${collectionId}` : paths.PLAYLISTS
    navigate(pathName) // Back should go back to Collections page, so don't update state.from
  }

  async function handleError() {
    notifications.notifyError(
      'Something went wrong submitting your responses. Please try again later.'
    )
    navigate(paths.HOME_AUTHED, { replace: true })
  }

  async function handleExit() {
    globalContext.analytics?.trackEvent(events.HOS_SURVEY_EXIT, {
      currentQuestionCode: checkinTracker?.currentNode?.code,
    })
    navigate(paths.HOME_AUTHED, { replace: true })
  }

  async function handleSuccess() {
    setIsComplete(true)
  }

  const copy = `Congrats${globalContext.user?.firstName ? `, ${globalContext.user?.firstName}` : ''}!`

  if (_.isNil(isComplete)) return null

  return (
    <div className={classNames('HosSurvey', styles.this)}>
      <Head title="Unlock Your Health Habit Kit" />
      <View className={styles.main}>
        <Container flush size="small">
          {isComplete ? (
            <Title
              image={<CompleteSvg aria-hidden />}
              subtext="You unlocked your personalized collection including blogs and classes picked to help you build your healthy habits this year."
              actions={
                <Button full onClick={handleClick}>
                  My Healthy Habit Kit
                </Button>
              }
              flush
              size="small">
              {copy}
            </Title>
          ) : (
            <OnboardingCheckinSingleForm
              checkinTracker={checkinTracker}
              onError={handleError}
              onExit={handleExit}
              onSuccess={handleSuccess}
            />
          )}
        </Container>
      </View>
      <footer className={styles.footer}>
        <Container full>
          <Text color="ink200" element="p" flush size="xsmall">
            This is not an official government survey
          </Text>
        </Container>
      </footer>
    </div>
  )
}
