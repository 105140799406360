import phoneNumbers from '@bold/common/constants/phone-numbers'
import { navigate } from 'gatsby'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Link from 'components/Link'
import Modal, { type ModalProps } from 'components/Modal'
import ModalConfirm from 'components/ModalConfirm'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import emails from 'constants/emails'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingBox from 'features/onboarding/components/OnboardingBox'
import programCompleteBadgeImage from 'images/badges/program-complete.svg'
import * as notifications from 'libs/notifications'
import { useAllUserExerciseProgramsForUser } from 'libs/program-query'
import * as programs from 'libs/programs'
import * as userApiRequest from 'libs/user-api-request'
import styles from './ProgramProgressionModals.module.scss'

type Props = {
  isChanging?: boolean
  numClassesCompleted?: number
  onClose?: Function
  program?: {
    alternateProgram?: {
      description: string
      title: string
    }
    similarProgram?: {
      description: string
      title: string
    }
    exerciseProgram?: {
      tags: string[]
    }
  }
} & Partial<ModalProps>

export default function ProgramProgressionModals({
  isChanging,
  numClassesCompleted,
  onClose,
  program,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const { data: allUserExerciseProgramsData } = useAllUserExerciseProgramsForUser()
  const allUserExercisePrograms = allUserExerciseProgramsData?.data
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [isNewUser, isSetNewUser] = React.useState(false)
  const [nextProgram, setNextProgram] = React.useState(null)
  const [showBadge, setShowBadge] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [showPostPelvicProgramSelection, setShowPostPelvicProgramSelection] =
    React.useState(isChanging)
  const [showProgramSelection, setShowProgramSelection] = React.useState(isChanging)

  React.useEffect(() => {
    if (isChanging) return
    setShowModal(true)

    globalContext.analytics?.trackEvent('Start program complete flow', {
      exerciseProgramTitle: programs.getProgramTitle(program),
      currentExerciseProgramId: program?.exerciseProgram?.id,
    })
  }, [globalContext.analytics, program, isChanging])

  const commonProps = {
    size: 'small',
    className: 'ProgramProgressionModals',
  }

  const handleConfirmCompletion = () => {
    setShowBadge(true)
    globalContext.analytics?.trackEvent('Program complete flow first modal click')
  }

  const handleConfirmCollectBadge = () => {
    const isPelvicHealthProgram = program?.exerciseProgram?.tags?.[0] === 'pelvic_health'
    if (isPelvicHealthProgram) {
      if (!allUserExercisePrograms || allUserExercisePrograms.length === 0) {
        return // TODO: Exit early if no exercise programs are available?
      }

      if (allUserExercisePrograms.length === 1) {
        isSetNewUser(true)
      } else {
        const mostRecentInactiveProgram = allUserExercisePrograms.find(
          (inActiveProgram) => !inActiveProgram.isActive
        )
        if (mostRecentInactiveProgram) {
          setNextProgram(mostRecentInactiveProgram)
        }
      }
      setShowPostPelvicProgramSelection(true)
      setShowModal(false)
    } else {
      setShowProgramSelection(true)
      setShowModal(false)

      globalContext.analytics?.trackEvent('Program complete flow second modal click', {
        similarProgramId: program?.similarProgram?.id,
        alternateProgramId: program?.alternateProgram?.id,
      })
    }
  }

  const handleProgramClick = async (clickedProgram: {
    id: string
    title: string
    description: string
    userExerciseProgramId?: string
  }) => {
    setIsDisabled(true)
    const isPelvicHealthProgram = program?.exerciseProgram?.tags?.[0] === 'pelvic_health'
    const response = isPelvicHealthProgram
      ? await userApiRequest.userExerciseProgramProgression(
          clickedProgram.userExerciseProgramId,
          clickedProgram.id
        )
      : await userApiRequest.userExerciseProgramProgression(clickedProgram.id)

    if (response.statusCode !== 200) {
      globalContext.analytics?.trackEvent(
        'Program complete flow - failed to progress user to new program',
        {
          selectedProgramId: clickedProgram.id,
        }
      )
      notifications.notifyError(
        `Sorry we couldn’t change your program right now. Please contact us at ${emails.DEFAULT} or call 833-701-1545 (toll-free).`
      )
      setIsDisabled(false)
      setShowProgramSelection(false)
      setShowModal(false)
      return
    }

    globalContext.analytics?.trackEvent('Program complete flow select program', {
      selectedProgramId: clickedProgram.id,
      title: programs.getProgramTitle(clickedProgram),
      description: clickedProgram.description,
    })
    await globalContext.updateUser()
    setShowProgramSelection(false)

    //TODO change this. Ideally, reloading the page is temp fix.
    window.location.reload()
  }

  const handleStartNewProgram = async () => {
    try {
      await userApiRequest.determineAndUpdateUserProfileProgramFocus()
      navigate(`${paths.PROGRAM_BUILD}?changeRequested=true`, { replace: true })
    } catch {
      notifications.notifyError(
        `Sorry we couldn’t change your program right now. Please contact us at ${emails.DEFAULT} or call 833-701-1545 (toll-free).`
      )
    }
  }

  const handleProgramSelectionClose = () => {
    setShowProgramSelection(false)
    if (onClose) onClose()
  }

  const similarProgram = program?.similarProgram
  const alternateProgram = program?.alternateProgram
  const count = [similarProgram, alternateProgram].filter(Boolean).length
  const nextProgramNumberClassesCompleted = nextProgram?.progress?.numClassesCompleted
  const nextProgramTotalLessons = nextProgram?.progress?.totalLessons
  const nextProgramTitle =
    nextProgram?.programType === 'dynamic'
      ? 'My Bold Program'
      : programs.getProgramTitle(nextProgram)

  const nextProgramDescription =
    nextProgram?.programType === 'dynamic'
      ? 'Your curated program focuses on increasing functional strength and conditioning your balance so that you can age well. In each class, Bold’s expert trainers share the best exercise form and tips to ensure you get the most out of your fitness routine.'
      : nextProgram?.exerciseProgramDetails?.description

  return (
    <>
      <ModalConfirm
        {...commonProps}
        {...props}
        title={`Congratulations${globalContext.user.firstName ? `, ${globalContext.user.firstName}!` : '!'}`}
        confirmText="Collect my badge"
        confirmButtonProps={{ color: 'yellow' }}
        hideCancel
        hideClose
        isOpen={showModal}
        onConfirm={handleConfirmCompletion}
        onRequestClose={() => setShowModal(false)}>
        <Text align="left" element="p">
          Great job on completing all {numClassesCompleted} classes in your program.
        </Text>
      </ModalConfirm>

      <ModalConfirm
        {...commonProps}
        {...props}
        hideClose
        hideCancel
        confirmText="Continue"
        confirmButtonProps={{ color: 'yellow' }}
        isOpen={showBadge}
        onConfirm={handleConfirmCollectBadge}
        onRequestClose={() => setShowBadge(false)}>
        <Row size="small">
          <Image src={programCompleteBadgeImage} alt="Program completion badge" />
        </Row>
        <Text element="p" weight="bold">
          You earned a badge!
        </Text>
      </ModalConfirm>

      {count === 0 && (
        <Modal
          {...commonProps}
          {...props}
          hideCancel
          hideClose
          isOpen={showProgramSelection}
          onRequestClose={handleProgramSelectionClose}>
          <Box color="grey">
            <Heading align="center" levelStyle={4}>
              Get your next program
            </Heading>
            <Text element="p" flush>
              Talk to a member of Team Bold to start your next program. Contact us at{' '}
              <Link to={phoneNumbers.DEFAULT} /> or <Link to={emails.DEFAULT} />.
            </Text>
          </Box>
        </Modal>
      )}

      {(similarProgram || alternateProgram) && (
        <Modal
          {...commonProps}
          {...props}
          hideCancel
          hideClose
          isOpen={showProgramSelection}
          onConfirm={handleProgramSelectionClose}
          onRequestClose={handleProgramSelectionClose}>
          <Box color="grey">
            <Heading align="center" levelStyle={4}>
              {count > 1 ? 'Pick your' : 'Your'} next program
            </Heading>
            <Text element="p">
              {`${count > 1 ? `Choose from 2` : `Your`} new ${pluralize('program', count)} ${
                count > 1 ? `that build` : `builds`
              } off of your last program. Click below to get started.`}
            </Text>
            {similarProgram && (
              <Row size="small">
                <Box
                  align="left"
                  color="grey"
                  className={styles.programSuggestion}
                  onClick={() => handleProgramClick(similarProgram)}>
                  <Row size="xxsmall">
                    <Text element="h3" size="large" weight="bold">
                      {programs.getProgramTitle(similarProgram)}
                    </Text>
                  </Row>
                  <Text element="p">{similarProgram.description}</Text>
                </Box>
              </Row>
            )}
            {alternateProgram && (
              <Row size="small">
                <Box
                  color="grey"
                  className={styles.programSuggestion}
                  onClick={() => handleProgramClick(alternateProgram)}>
                  <Row size="xxsmall">
                    <Text element="h3" size="large" align="left" weight="bold">
                      {programs.getProgramTitle(alternateProgram)}
                    </Text>
                  </Row>
                  <Text element="p" align="left">
                    {alternateProgram.description}
                  </Text>
                </Box>
              </Row>
            )}
            <Text element="p" flush>
              Need help? Contact us at <Link to={phoneNumbers.DEFAULT} /> or{' '}
              <Link to={emails.DEFAULT} />.
            </Text>
          </Box>
        </Modal>
      )}

      <Modal
        {...commonProps}
        {...props}
        hideClose
        hideCancel
        isOpen={showPostPelvicProgramSelection}
        onRequestClose={handleProgramSelectionClose}>
        {!isNewUser && (
          <Box color="grey" className={styles.programSuggestion}>
            <Heading align="left" levelStyle={4}>
              Pick up where you left off
            </Heading>
            <Text align="left" element="p">
              You’ve taken {nextProgramNumberClassesCompleted} of {nextProgramTotalLessons} classes
              in {nextProgramTitle}. Return to this program to finish all the classes.
            </Text>
            <Row size="small">
              <OnboardingBox icon="shuffle" title={nextProgramTitle}>
                <Text element="p" flush>
                  {nextProgramDescription}
                </Text>
              </OnboardingBox>
            </Row>
            <Button
              color="yellow"
              disabled={isDisabled}
              full
              onClick={() => handleProgramClick(nextProgram)}>
              {isDisabled ? 'Loading program…' : 'Continue'}
            </Button>
          </Box>
        )}
        {isNewUser && (
          <Box color="grey" className={styles.programSuggestion}>
            <Title
              subtext="Continue with your Bold journey."
              actions={
                <Button color="yellow" full onClick={handleStartNewProgram}>
                  Start my program
                </Button>
              }
              align="left"
              flush
              size="small">
              Start your next program
            </Title>
          </Box>
        )}
      </Modal>
    </>
  )
}
