import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Button from 'components/Button'
import ButtonFilter from 'components/ButtonFilter'
import ButtonFilter2 from 'components/ButtonFilter2'
import Buttons from 'components/Buttons'
import ButtonWithModal from 'components/ButtonWithModal'
import Container from 'components/Container'
import Head from 'components/Head'
import Header from 'components/Header'
import HeaderSubheader from 'components/HeaderSubheader'
import Input from 'components/Input'
import Loader from 'components/Loader'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonBox from 'features/lesson/components/LessonBox'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as exploreApiRequest from 'libs/explore-api-request'
import * as lesson from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as url from 'libs/url'
import constants from 'styles/constants.module.scss'
import styles from './ExploreLessons.module.scss'
import {
  ExploreFiltersContextProvider,
  useExploreFiltersContext,
} from './contexts/ExploreFiltersContext'
import { ReactComponent as ArrowLeftSvg } from './images/arrow---left.svg'

const DURATION_INTIAL: [] = []
const INTENSITY_INITIAL: [] = []
const POSITION_INTIAL = ''
const TRAINER_INITIAL = ''
const TYPE_INITIAL = ''

function ExploreLessons() {
  useEventPageVisited(events.EXPLORE_PAGE)
  const exploreFiltersContext = useExploreFiltersContext()
  const globalContext = useGlobalContext()
  const inputRef = React.useRef()
  const queryString = url.getQueryString()
  const {
    classType: classTypeQuery,
    duration: durationQuery,
    intensity: intensityQuery,
    position: positionQuery,
    preset: presetQuery,
    searchKeywords: searchKeywordsQuery,
    trainer: trainerQuery,
  } = queryString
  const [classType, setClassType] = React.useState(classTypeQuery ?? TYPE_INITIAL)
  const [classTypeOptions, setClassTypeOptions] = React.useState([])
  const [duration, setDuration] = React.useState(
    !_.isEmpty(durationQuery)
      ? durationQuery.map((string) => Number.parseInt(string, 10))
      : DURATION_INTIAL
  )
  const [filter, setFilter] = React.useState({})
  const [filterLoaded, setFilterLoaded] = React.useState(false)
  const [intensity, setIntensity] = React.useState(
    !_.isEmpty(intensityQuery)
      ? intensityQuery.map((string) => Number.parseInt(string, 10))
      : INTENSITY_INITIAL
  )
  const [isEndOfResults, setIsEndOfResults] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [lessons, setLessons] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [position, setPosition] = React.useState(positionQuery ?? POSITION_INTIAL)
  const [positionOptions, setPositionOptions] = React.useState([])
  const [preset] = React.useState(presetQuery)
  const [removedLessonId, setRemovedLessonId] = React.useState('')
  const [searchKeywords, setSearchKeywords] = React.useState(searchKeywordsQuery ?? [])
  const [trainer, setTrainer] = React.useState(trainerQuery ?? TRAINER_INITIAL)
  const [trainerOptions, setTrainerOptions] = React.useState([])

  // once default values are loaded, clear state
  window.history.replaceState(null, '')

  React.useEffect(() => {
    if (!inputRef.current) return

    // buggy without when navigating from explore without this
    setTimeout(() => {
      inputRef.current.focus()
    }, 0)
  }, [])

  const pageRef = React.useRef(page)
  const setPageRef = (data: number) => {
    pageRef.current = data
    setPage(data)
  }
  const isLoadingRef = React.useRef(isLoading)
  const setIsLoadingRef = (data: boolean) => {
    isLoadingRef.current = data
    setIsLoading(data)
  }
  const isEndOfResultsRef = React.useRef(isEndOfResults)
  const setIsEndOfResultsRef = (data: boolean) => {
    isEndOfResultsRef.current = data
    setIsEndOfResults(data)
  }

  React.useEffect(() => {
    function handleScroll() {
      if (isEndOfResultsRef.current) {
        return
      }
      const userScrollHeight = window.innerHeight + window.scrollY
      const windowBottomHeight = document.documentElement.offsetHeight
      if (!isLoadingRef.current && Math.abs(userScrollHeight - windowBottomHeight) < 1) {
        setPageRef(pageRef.current + 1)
      }
    }
    const throttledHandleScroll = _.throttle(handleScroll, 500)

    window.addEventListener('scroll', throttledHandleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // returned function will be called on component unmount
    return () => {
      setPageRef(1)
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [isLoadingRef])

  const getFirstNameCount = (list: string[]) => {
    const firstNameCount: Record<string, number> = {}

    list
      .filter((fullName) => fullName !== null)
      .forEach((fullName) => {
        const firstName = fullName.split(' ')[0]
        firstNameCount[firstName] = (firstNameCount[firstName] || 0) + 1
      })

    return firstNameCount
  }

  React.useEffect(() => {
    const mapListToSelectOptions = (
      list,
      capitalize = true,
      sorted = false,
      icons = false,
      split = false
    ) => {
      const sortedList = sorted ? _.sortBy(list) : list
      const firstNameCountMap = getFirstNameCount(list)

      return sortedList.map((value) => {
        let label = value

        if (capitalize) {
          label = _.capitalize(label)
        }

        if (split) {
          const splitLabel: string = label.split(' ')
          if (splitLabel[0].includes('Dr.')) {
            label = `${splitLabel[0]} ${splitLabel[2]}`
          } else {
            label =
              firstNameCountMap[splitLabel[0]] > 1
                ? `${splitLabel[0]} ${_.capitalize(splitLabel[1][0])}`
                : splitLabel[0]
          }
        }

        return {
          icon: icons ? lesson.getTrainerImage(value) : null,
          label,
          value,
        }
      })
    }

    async function getFilterOptions() {
      const response = await lessonApiRequest.getAllAvailableLessonFilterOptions()
      const filters = response.data

      if (!filters) return

      setClassTypeOptions(mapListToSelectOptions(filters.classTypes, true, true))
      setPositionOptions(mapListToSelectOptions(filters.positions))
      setTrainerOptions(
        mapListToSelectOptions(filters.instructorNames.filter(Boolean), false, true, true, true)
      )
    }
    getFilterOptions()
  }, [])

  React.useEffect(() => {
    async function getLessons() {
      const response = await exploreApiRequest.getLessons({
        filter,
        isLiveClass: preset === 'isLiveClass' ? 'true' : undefined,
        isOnDemandClass: preset === 'isOnDemandClass' ? 'true' : undefined,
        page,
        searchKeywords,
      })

      if (response && response.statusCode === 200) {
        if (response.data?.length === 0) {
          setIsEndOfResultsRef(true)
        }

        setLessons(
          lessons
            .concat(response.data)
            .filter(
              (lessonData, index, self) => index === self.findIndex((l) => l.id === lessonData.id)
            )
        )
      }
    }

    async function loadLessons() {
      if (!filterLoaded) return

      setIsLoadingRef(true)

      await getLessons()

      setIsLoadingRef(false)
    }

    loadLessons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, filterLoaded, removedLessonId])

  React.useEffect(() => {
    setIsEndOfResultsRef(false)
    setLessons([])
    setPageRef(1)
    setFilterLoaded(true)
    const filterObject: any = {}
    if (!_.isEmpty(trainer)) filterObject.trainer = trainer
    if (!_.isEmpty(classType)) filterObject.classType = classType
    if (!_.isEmpty(duration)) filterObject.duration = duration
    if (!_.isEmpty(intensity)) filterObject.intensity = intensity
    if (!_.isEmpty(position)) filterObject.position = position
    if (!_.isEmpty(searchKeywords)) filterObject.searchKeywords = searchKeywords
    setFilter(filterObject)
    url.setUrl({ queryStringObject: filterObject })
  }, [classType, duration, intensity, position, searchKeywords, trainer])

  function handleFilterButtonClick(value) {
    exploreFiltersContext.update(value)
  }

  function handleRemoveLessonFavorite(id) {
    setRemovedLessonId(id)
  }

  //function handleLessonClassTypeClick(value) {
  //  setClassType(value)
  //}

  function handleLessonTrainerClick(value) {
    setTrainer(value)
  }

  function handleFilterSelect(value, function_, eventFilterName) {
    function_(value)
    globalContext.analytics?.trackEvent(`Explore: Filter: ${eventFilterName}`, {
      value,
    })
  }

  function handleSearch(event) {
    const { value } = event.target
    const strings = value.split(' ')
    setSearchKeywords(strings.filter((item) => item.length >= 3).map((item) => item.toLowerCase()))
    globalContext.analytics?.trackEvent('Explore: Filter: Search', {
      query: value,
    })
  }

  // TODO: These options should just be fetched from the api

  const durationMediumMin = 20
  const durationMediumMax = 40
  const durationOptions = [
    {
      label: `< ${durationMediumMin} mins`,
      value: [0, durationMediumMin - 1],
    },
    {
      label: `${durationMediumMin}–${durationMediumMax} mins`,
      value: [durationMediumMin, durationMediumMax],
    },
    {
      label: `> ${durationMediumMax} mins`,
      value: [durationMediumMax + 1, 999],
    },
  ]

  const intensityOptions = [
    {
      label: 'Beginner',
      value: [0, lesson.INTENSITY_MODERATE_MIN - 1],
    },
    {
      label: 'Moderate',
      value: [lesson.INTENSITY_MODERATE_MIN, lesson.INTENSITY_MODERATE_MAX],
    },
    {
      label: 'Advanced',
      value: [lesson.INTENSITY_MODERATE_MAX + 1, 10],
    },
  ]

  const filtersButton = (
    <ButtonWithModal
      modal={
        <ModalBox title="Filter classes" align="left">
          <Row size="small">
            <ButtonFilter2
              options={trainerOptions}
              value={trainer}
              onClear={() => setTrainer(TRAINER_INITIAL)}
              onSelect={(value) => handleFilterSelect(value, setTrainer, 'Trainer')}>
              Trainer
            </ButtonFilter2>
          </Row>
          <Row size="small">
            <ButtonFilter2
              options={classTypeOptions}
              value={classType}
              onClear={() => setClassType(TYPE_INITIAL)}
              onSelect={(value) => handleFilterSelect(value, setClassType, 'Class type')}>
              Type
            </ButtonFilter2>
          </Row>
          <Row size="small">
            <ButtonFilter2
              options={durationOptions}
              value={duration}
              onClear={() => setDuration(DURATION_INTIAL)}
              onSelect={(value) => handleFilterSelect(value, setDuration, 'Duration')}>
              Duration
            </ButtonFilter2>
          </Row>
          <Row size="small">
            <ButtonFilter2
              options={intensityOptions}
              value={intensity}
              onClear={() => setIntensity(INTENSITY_INITIAL)}
              onSelect={(value) => handleFilterSelect(value, setIntensity, 'Intensity')}>
              Difficulty
            </ButtonFilter2>
          </Row>
          <Row size="small">
            <ButtonFilter2
              options={positionOptions}
              value={position}
              onClear={() => setPosition(POSITION_INTIAL)}
              onSelect={(value) => handleFilterSelect(value, setPosition, 'Position')}>
              Position
            </ButtonFilter2>
          </Row>
        </ModalBox>
      }
      color="white"
      pill
      size="small"
      modalProps={{
        size: 'large',
      }}>
      Filters
    </ButtonWithModal>
  )

  return (
    <div className="ExploreLessons">
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <Header
        subheader={
          <HeaderSubheader>
            <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
            <Row size="smallx">
              <Button to={paths.EXPLORE} icon={<ArrowLeftSvg />} level="text">
                Back to Explore
              </Button>
            </Row>
            <div className={styles.filters}>
              <Buttons>
                <Media query={{ minWidth: constants.GT_TABLET }}>
                  <ButtonFilter
                    options={trainerOptions}
                    shouldClose={exploreFiltersContext.open !== 'trainer'}
                    value={trainer}
                    onClear={() => setTrainer(TRAINER_INITIAL)}
                    onClick={() => handleFilterButtonClick('trainer')}
                    onSelect={(value) => handleFilterSelect(value, setTrainer, 'Trainer')}>
                    Trainer
                  </ButtonFilter>
                </Media>
                <Media query={{ minWidth: constants.GT_TABLET }}>
                  <ButtonFilter
                    options={classTypeOptions}
                    shouldClose={exploreFiltersContext.open !== 'classType'}
                    value={classType}
                    onClear={() => setClassType(TYPE_INITIAL)}
                    onClick={() => handleFilterButtonClick('classType')}
                    onSelect={(value) => handleFilterSelect(value, setClassType, 'Class type')}>
                    Type
                  </ButtonFilter>
                </Media>
                <Media query={{ minWidth: constants.GT_TABLET }}>
                  <ButtonFilter
                    options={durationOptions}
                    shouldClose={exploreFiltersContext.open !== 'duration'}
                    value={duration}
                    onClear={() => setDuration(DURATION_INTIAL)}
                    onClick={() => handleFilterButtonClick('duration')}
                    onSelect={(value) => handleFilterSelect(value, setDuration, 'Duration')}>
                    Duration
                  </ButtonFilter>
                </Media>
                <Media query={{ minWidth: constants.GT_TABLET }}>
                  <ButtonFilter
                    options={intensityOptions}
                    shouldClose={exploreFiltersContext.open !== 'intensity'}
                    value={intensity}
                    onClear={() => setIntensity(INTENSITY_INITIAL)}
                    onClick={() => handleFilterButtonClick('intensity')}
                    onSelect={(value) => handleFilterSelect(value, setIntensity, 'Intensity')}>
                    Difficulty
                  </ButtonFilter>
                </Media>
                <Media query={{ minWidth: constants.GT_TABLET }}>
                  <ButtonFilter
                    options={positionOptions}
                    shouldClose={exploreFiltersContext.open !== 'position'}
                    value={position}
                    onClear={() => setPosition(POSITION_INTIAL)}
                    onClick={() => handleFilterButtonClick('position')}
                    onSelect={(value) => handleFilterSelect(value, setPosition, 'Position')}>
                    Position
                  </ButtonFilter>
                </Media>
                <Media query={{ maxWidth: constants.TABLET }}>{filtersButton}</Media>
                <Input
                  //type="search"
                  autoFocus
                  defaultValue={!_.isEmpty(searchKeywords) ? searchKeywords.join(' ') : ''}
                  inputRef={inputRef}
                  placeholder="Search for trainer, type of class…"
                  className={styles['search-input']}
                  onKeyUp={_.throttle(handleSearch, 250)}
                />
              </Buttons>
            </div>
          </HeaderSubheader>
        }
      />
      <View color="grey" footer>
        <Container flush size="xxlarge">
          {!isLoading && _.isEmpty(lessons) ? (
            <Row size="small">
              <Text align="center" size="large" weight="bold">
                No matching classes
              </Text>
            </Row>
          ) : (
            <div className={styles.grid}>
              {lessons.map((item) => (
                <LessonBox
                  data={item}
                  key={item.id}
                  //onClassTypeClick={handleLessonClassTypeClick}
                  onRemoveLessonFavorite={handleRemoveLessonFavorite}
                  onTrainerClick={handleLessonTrainerClick}
                />
              ))}
            </div>
          )}
          {isLoading && <Loader overlay={false} />}
          {!_.isEmpty(lessons) && isEndOfResultsRef.current && (
            <Text align="center">End of results</Text>
          )}
        </Container>
      </View>
    </div>
  )
}

export default function ExploreLessonsWithContext() {
  return (
    <ExploreFiltersContextProvider>
      <ExploreLessons />
    </ExploreFiltersContextProvider>
  )
}
